// App.js
import React, { useState } from 'react';
import PublicKeyPattern from './PublicKeyPattern'; // Make sure you have this component created
import './App.css';

const App = () => {
  const [hash, setHash] = useState('');

  const handleChange = (event) => {
    setHash(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form from submitting and refreshing the page
    // Add logic here if you want to perform an action when the form is submitted
  };

  const handleAboutClick = () => {
    // Redirect to the same address as the "My Manuscript" button
    window.location.href = 'https://drive.google.com/drive/folders/1OIWfF11SJt3TmEB7BoRdOdstNurHJDgx?usp=sharing';
  };

  const handleContactClick = () => {
    // Open the default email client with the specified email address
    window.location.href = 'https://deusopus.carrd.co';
  };

  return (
    <div className="App">
      {/* Navbar */}
      <nav>
        <ul>
          <li onClick={handleAboutClick}>My Book</li>
          <li onClick={handleContactClick}>Contact</li>
        </ul>
      </nav>

      <h1>Bitcoin Hash Pattern Generator</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={hash}
          onChange={handleChange}
          placeholder="Enter Bitcoin hash"
        />
        <button type="submit">Generate</button>
      </form>
      <PublicKeyPattern hash={hash} />
    </div>
  );
};

export default App;
